import Vue from 'vue'
import Vuex from 'vuex'
import main from "@/main";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        languages: new Map([
            ['zh', '简体中文'],
            ['ru', 'Русский язык'],
            ['en', 'English'],
        ])
    },
    mutations: {
        ChooseLanguage(state, languageItem) {
            main.$i18n.locale = languageItem;
        }
    },
    actions: {}
})