<template>
  <div>

    <div class="container">

      <img src="imgs/contact_us.jpg"/>
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="thumbnail">
            <img src="/icons/address.svg"/>
            <div class="caption">
              <h3>{{ $t('page_contact_us.address.title') }}</h3>
              <h4 style="text-align: left">{{ $t('page_contact_us.address.content') }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="thumbnail">
            <img src="/icons/email.svg"/>
            <div class="caption">
              <h3>{{ $t('page_contact_us.email.title') }}</h3>
              <h4 style="text-align: left">{{ $t('page_contact_us.email.content') }}</h4>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-4">
          <div class="thumbnail">
            <img src="/icons/tel.svg"/>
            <div class="caption">
              <h3>{{ $t('page_contact_us.tel.title') }}</h3>
              <h4 style="text-align: left">{{ $t('page_contact_us.tel.content') }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="gd-map">
        <amap :center="center" :zoom="zoom">
          <amap-marker :position="[131.131462, 44.408325]"/>
        </amap>
      </div>
    </div>


  </div>
</template>

<script>


export default {
  name: "contact",
  data() {
    const self = this;
    return {
      zoom: 16,
      center: [131.131462, 44.408325],
      position1: [131.131462, 44.408325],
      lng: 0,
      lat: 0,
      loaded: false,
      plugin: [{
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 100,          //超过10秒后停止定位，默认：无穷大
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        showButton: true,        //显示定位按钮，默认：true
        buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
        showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
        showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
        panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
        // zoomToAccuracy: true,//定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
        extensions: 'all',
        pName: 'Geolocation',
        events: {
          init(o) {
            // o 是高德地图定位插件实例
            o.getCurrentPosition((status, result) => {

              console.log(result)
              if (result && result.position) {
                self.lng = result.position.lng;
                self.lat = result.position.lat;
                self.center = [self.lng, self.lat];
                self.zoom = 100;
                self.loaded = true;
                self.$nextTick();
              }
            });
          }
        }
      }]
    }
  }
}
</script>

<style scoped>
.container > img {
  width: 100%;
  margin-bottom: 10px;
}

.gd-map {
  margin-bottom: 10px;
  width: 100%;
  height: 300px;
}
.thumbnail>img{
  margin: 10px auto;
  width: auto;
  height: 40px;
}
.caption>h3
{
  color: #3b3b3b;
  font-weight: bold;
}

.caption>h4
{
  padding: 10px;
  text-align: center !important;
  color: #5b5b5b;
  font-weight: bold;
}

.thumbnail
{
  border: none;
  border-radius: 0px;

  box-shadow: 0 0 0 1px rgba(0,0,0,.05),0 2px 4px 1px rgba(0,0,0,.09);
}

</style>