// src/plugins/VueI18n/index.js：
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// 定义各语言的配置
const messages = {
    zh: {
        menu: {
            home: "主页",
            intro: "公司简介",
            service: "服务内容",
            culture_honor: "文化与荣誉",
            contact_us: "联系我们",
            job_site: "工作现场",
            service_concept: "服务理念",
            successful_case: "成功案例"
        },
        message: {
            hello: '你好，世界',
        },
        name: '可乐',
        footer: {
            company_name: "绥芬河市广平装卸服务有限公司"
        },
        page_contact_us: {
            address: {
                title: "公司地址",
                content: "绥芬河市乌苏里大街28号"
            },
            email: {
                title: "E-mail",
                content: "guangping56@126.com\n"
            },
            tel: {
                title: "联系电话",
                content: "+86 13763618908\n"
            }
        },
        page_intro: {
            article_company_intro:
                {
                    tag: "intro",
                    title: "公司简介",
                    content: "　　绥芬河市广平装卸服务有限公司坐落于著名的“百年口岸城市”——绥芬河，" +
                        "绥芬河是一座风光秀丽的边境山城，东与俄罗斯滨海边疆区接壤，边境线长27公里，" +
                        "也是承接我国振兴东北和俄罗斯开发远东两大战略的重要节点城市，" +
                        "被誉为连接东北亚和走向亚太地区的“黄金通道”。成立于2011年4月份，" +
                        "注册资金3000万元，经营范围：道路货物运输；国际道路货物运输、货物进出口等相关业务。" +
                        "本公司拥有场地约5万平方米，集装箱拖车120余台，吊车20台，叉车20台；" +
                        "货场为仓储集散地，对外开放使用，用于存放木材、集装箱、各类车辆等。"
                },
            article_kjpt:
                {
                    tag: "kjpt",
                    title: "跨境平台",
                    content: "　　一个专门为国内跨境货物提供便捷物流方案及解决货品清关问题创立的公司。"
                },
            article_tpybx:
                {
                    tag: "tpybx",
                    title: "太平保险公司",
                    content: "　　与太平保险公司合作，为跨境运输的货品保驾护航。"
                },
            article_elsqg:
                {
                    tag: "elsqg",
                    title: "俄罗斯本土清关公司",
                    content: "　　与俄罗斯本土清关公司合作，丰富经验将缩短对俄出口物流的时间，提高货物运行效率。"
                }
        },
        page_service: {
            article_primary_business: {
                title: "主营业务",
                content_1: "人力装卸、人力搬运",
                content_2: "跟车提货、送货服务",
                content_3: "货物吊装、上下楼服务",
                content_4: "货物运输、包装包卸"
            },
            article_service_process:{
                title:"合作流程",
                mode:[
                    {
                        description:"一年及以上合同期",
                        content:"双方洽谈->报价->签装卸合同->安排固定工人驻场工作->月结费用"
                    },
                    {
                        description: "单次临时调动服务",
                        content:"电话咨询->报价->提前确定作业时间->现场作业->作业完后即时结算"
                    }
                ]
            },
            article_service_price:{
                title:"服务报价",
                high_density_cargo:{
                    name:"重货",
                    price:"6-13元/吨"
                },

            },
            article_trans_mode:
                {
                    tag: "trans_mode",
                    title: "运输方式",
                    content: "　　采用铁路运输或公路运输。铁路运输采用中欧班列、国际列车两种模式，公路运输是俄罗斯快递公司通过汽运集装箱模式。"
                },
            article_effectiveness:
                {
                    tag: "time",
                    title: "整体时效",
                    content: "　　铁路运输和公路运输两种方式整体时效是10—12天。\n" +
                        "　　(偏远地区除外)"
                },
            article_service:
                {
                    tag: "service",
                    title: "服务",
                    content: "　　为国内跨境货物提供俄罗斯全境及部分地区的接箱服务及清关结算服务。国内的跨境电商平台提供更优质的物流服务。做到价格最优、运输速度最快、安全系数最高、售后服务最佳的效果。"
                },
            article_city:
                {
                    tag: "city",
                    title: "中转城市",
                    content: "　　绥芬河市——中国境内所有城市\n\n" +
                        "　　绥芬河市——俄罗斯"
                }
        }
    }
};

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
    locale: 'zh', // 设置默认地区
    messages, // 设置配置信息
});

export default i18n;
