import VueRouter from "vue-router";
import intro from "@/components/pages/intro";
import service from "@/components/pages/service";
import culture from "@/components/pages/culture";
import contact from "@/components/pages/contact";
import job_site from "@/components/pages/job_site";
import service_concept from "@/components/pages/service_concept";
import successful_case from "@/components/pages/successful_case";

var router = new VueRouter({
    routes: [
        {path: "/", component: intro},
        {path: "/intro", component: intro},
        {path: "/service", component: service},
        {path: "/culture_honor", component: culture},
        {path: "/contact_us", component: contact},
        {path: "/job_site", component: job_site},
        {path: "/service_concept", component: service_concept},
        {path: "/successful_case", component: successful_case}
    ],
    mode: "history"
})

export default router
