<template>
  <nav class="navbar navbar-default navbar-inverse">
    <div class="container">
      <!--      <a class="navbar-brand" href="#">绥芬河市广平装卸服务有限公司</a>-->
      <div class="navbar-header">

        <button type="button" class="navbar-toggle" data-toggle="collapse"
                data-target="#example-navbar-collapse">
          <span class="sr-only">切换导航</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>

        </button>


      </div>
      <div class="collapse navbar-collapse" id="example-navbar-collapse">
        <div class="row">
          <div class="col-md-8">
            <ul class="nav navbar-nav">
              <li @click="ActiveLiTag($event)" class="active">
                <router-link to="/">{{ $t('menu.home') }}</router-link>
              </li>

              <li @click="ActiveLiTag($event)">
                <router-link to="/service">{{ $t('menu.service') }}</router-link>
              </li>

              <li @click="ActiveLiTag($event)">
                <router-link to="/job_site">{{ $t('menu.job_site') }}</router-link>
              </li>
              <li @click="ActiveLiTag($event)">
                <router-link to="/service_concept">{{ $t('menu.service_concept') }}</router-link>
              </li>
              <li @click="ActiveLiTag($event)">
                <router-link to="/successful_case">{{ $t('menu.successful_case') }}</router-link>
              </li>
              <li @click="ActiveLiTag($event)">
                <router-link to="/contact_us">{{ $t('menu.contact_us') }}</router-link>
              </li>


            </ul>
          </div>
          <div class="col-md-4 ">
            <!--
            <div class="ul-lan">
              <ul>
                <li><span class="glyphicon glyphicon-globe" id="language-icon" aria-hidden="true"></span></li>
                <li v-for="(item, index) in this.$store.state.languages" :key="index"
                    @click="ChooseLanguage((item[0]))"><a>{{ item[1] }}</a>
                </li>
              </ul>
            </div>
          -->


          </div>

        </div>


      </div>


    </div>
  </nav>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import $ from 'jquery'
import I18n from "@/plugins/VueI18n/index"

export default {
  name: "Header",
  created() {
    document.title = I18n.messages["zh"].footer.company_name;
  },
  methods: {
    ChooseLanguage(option) {
      this.$store.commit('ChooseLanguage', option);
      console.log(option);
      document.title = I18n.messages[option].footer.company_name;


    },
    ActiveLiTag(tag) {
      // console.log($);
      $(".navbar-nav").children().removeClass("active");

      tag.currentTarget.className += "active";

    }

  }
}
</script>

<style scoped>
.ul-lan {
  z-index: 50;

}

.ul-lan > ul {
  margin-top: revert;
}

.ul-lan > ul > li {
  display: inline;
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 5px;

}


.ul-lan > ul > li > a {
  display: inline;
  text-align: left;
  color: white;
  margin: 0;

}

.ul-lan > ul > li > a:hover {
  cursor: pointer;
  /*font-weight: bold;*/
  text-decoration: none;
}

.navbar-nav {
  font-size: 16px;
  font-weight: bold;
}

.btn-group {

}

.btn-link {
  color: white;
}

#language-icon {
  padding-right: 10px;
  color: white;
  background: #C00000;
}

.container-fluid {
  background: #C00000;
  color: white;
}

.navbar {
  border-radius: 0px;
  margin-bottom: 10px;
}

.navbar-default {
  color: white !important;
}

.navbar-inverse {
  background-color: #C00000;
  border: solid 0px;
  color: white;
}

.navbar-inverse .navbar-nav > .active > a {
  background: #005390;
  color: white;
}

.navbar-inverse .navbar-brand {
  color: white;
}

.navbar-inverse .navbar-nav > li > a {
  color: white;
}

.navbar-inverse .navbar-toggle {
  border: solid 0px;
  border-color: white;
}

.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background: #C00000;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: white;
}


</style>