import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import i18n from "@/plugins/VueI18n"
import Router from "vue-router"
import router from "@/router";
import store from "@/store";
import AmapVue from '@amap/amap-vue';

AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = 'a835c143dae4958a1cc7f738768cab8f';
AmapVue.config.plugins = ['AMap.ToolBar', 'AMap.MoveAnimation'];
Vue.use(AmapVue);


Vue.config.productionTip = false
Vue.use(Router)

export default new Vue({
    $,
    render: h => h(App),
    i18n,
    router,
    store
}).$mount('#app')
