import { render, staticRenderFns } from "./service_concept.vue?vue&type=template&id=40389a38&scoped=true&xmlns%3A=true&"
import script from "./service_concept.vue?vue&type=script&lang=js&"
export * from "./service_concept.vue?vue&type=script&lang=js&"
import style0 from "./service_concept.vue?vue&type=style&index=0&id=40389a38&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40389a38",
  null
  
)

export default component.exports