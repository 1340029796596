<template>
  <div id="app">
    <keep-alive>
      <Header></Header>
    </keep-alive>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <keep-alive>
      <Footer></Footer>
    </keep-alive>


  </div>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {Footer, Header},
  created() {

  },
  data() {
    return {};
  },
  methods: {}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
}
</style>
