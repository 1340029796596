<template>
  <div class="cpy-right text-center bg-theme">
    <p class="text-wh" style="padding: 5px">Copyright © 2021 <a target="_self" href="https://www.guangping56.com"
                                                                style="color: white;text-decoration: white;:hover:{text-decoration-line:underline;}">www.guangping56.com</a>
      All rights reserved.

    </p>
    <div>
<!--      <img src="/imgs/common/ICPBeian.svg" height="20px">-->
      <a target="_blank" href="https://beian.miit.gov.cn/">黑ICP备2021005448号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.cpy-right {
  background: #C00000;
  padding: 20px;
  color: white;
}

.cpy-right > div > a {
  color: white;
}

.text-wh {
  margin: 0;
}
</style>